@import '../../styles/variables.scss';

$spacing-margin: 3vh;

.loginItems {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.errorMessage {
  size: 1.2em;
  color: $podpop-red;
  padding: 0 10px 10px 10px;
  width: 80%;

  & .alert {
    width: 0.8em;
    padding-right: 6px;
  }
}

.message {
  color: $podpop-black;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  margin: 10px 0;
}

.loginPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 680px;
}

.loginbox {
  width: 450px;
  padding: 20px;
  background-color: $podpop-white;
}

.buttonbar {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: $spacing-margin 0;
  text-align: center;

  & button {
    width: 50%;
  }
}

.loginHeader {
  text-align: center;
  justify-content: center;
  margin: 0 10%;

  & .logo {
    width: 100%;
    margin-bottom: 15%;
    height: auto;
  }
}

.inputs {
  margin: 10px 36px;
  width: 80%;
}
.password {
  margin-top: 10px;
  margin-bottom: 0;
}

.inputBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.subOptions {
  font-size: 0.8em;
  margin: 0 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}

.centerText {
  text-align: center;
  justify-content: center;
}

.visitText {
  margin-top: 3vh;
  text-align: center;
}

a {
  text-decoration: none;
  color: $podpop-blue;
  &:hover {
    color: darken($podpop-blue, 10%);
  }
}

.forgotPass {
  cursor: pointer;
  color: darken($podpop-blue, 10%);

  &:hover {
    color: $podpop-blue;
  }
}

.resetModal {
  & .helpResetText {
    text-align: center;
    font-size: 1.3em;
    padding-bottom: 36px;
  }

  & .errorMessage {
    font-size: 0.9em;
  }
}
