// .Header {
//     display: flex;
//     position:absolute;
// 	top:0;
// 	left:0;
// 	right:0;
// 	height:60px;
// 	background-color: #6a6;
// 	overflow:hidden;
//     z-index: 150;
// }

.HeaderLogo {
	background-image: url(../../images/header_logo.png);
	width: 180px;
	background-position: center; /* Center the image */
	background-repeat: no-repeat; /* Do not repeat the image */
	background-size: auto;
}

@import '../../styles/variables.scss';

header {
  background-color: $color-pp-green;
  padding: 0;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  // box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.4);
  transition: all 500ms;

  & a {
    text-decoration: none;
    color: #e0e0e0;
    transition: color 0.1s ease;
    transition-timing-function: ease-out;

    &:hover {
      color: $color-pp-green-dark;
    }
    &:active {
      color: $color-pp-green-dark;
    }
  }

  & .topNavigation {
    padding: 0;
    display: flex;
    justify-content: flex-end;
    transition: all 300ms;

    & ul {
      background: $color-pp-green;

      list-style: none;
      margin: 0;
      padding: 0;
    }

    & li {
      color: $podpop-white;
      background: $color-pp-green;
      display: block;
      float: left;
      padding: 1rem;
      position: relative;
      text-decoration: none;
      transition-duration: 0.5s;

      &.primary {
        line-height: 2em;
      }

      &:hover {
        background: lighten($color-pp-green, 10%);
        cursor: pointer;
        color: $color-pp-green-dark;
      }

      &:active {
        color: $color-pp-green-dark;
      }
    }

    & ul li ul {
      background: $color-pp-green;
      visibility: hidden;
      opacity: 0;
      min-width: 5rem;
      position: absolute;
      transition: all 0.5s ease;
      margin-top: 0.8rem;
      left: 0;
      display: none;
    }

    & ul li:last-child ul {
      right: 0;
      left: auto;
      width: 200px;
    }

    & ul li:hover > ul,
    ul li:focus-within > ul, /* this is the line we add */
    ul li ul:hover {
      visibility: visible;
      opacity: 1;
      display: block;
    }

    & ul li ul li {
      clear: both;
      width: 100%;
      padding: 16px;
    }

    & a,
    .linkItem {
      text-decoration: none;
      color: #e0e0e0;
      transition: color 0.1s ease;
      transition-timing-function: ease-out;

      &:hover {
        color: $color-pp-green-dark;
      }
      &:active {
        color: $color-pp-green-dark;
      }
    }

    & .icon {
      display: none;
      font-size: 15px;
    }
  }
}

.logoHolder {
  padding: 6px;
  display: flex;
  text-decoration: none;
  & img {
    height: 40px;
  }

  & .logoText {
    padding-top: 6px;
    padding-left: 16px;
    font-size: 1.6em;
    font-weight: bold;
    color: $podpop-white;
    text-decoration: none;

    & .versionNumber {
      font-size: 0.4em;
      color: rgba(255, 255, 255, 0.3);
    }
  }
}

.userEmail {
  font-size: 0.8em;
  color: $podpop-lightgrey;
}
.userIcon {
  width: 26px;
  height: 26px;
  padding: 0;
  display: inline;
}

@media (max-width: #{$small-screen}) {
  header {
    padding: 4px;
    transition: height 0.2s;
    transition-timing-function: ease-out;

    & .topNavigation {
      margin-top: 0;
      flex-direction: column;

      &.activeBurger {
        & ul {
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          flex-direction: column;
          height: 100vh;
        }
        & li {
          display: block;
        }

        & ul li ul {
          background: $podpop-navy;
          visibility: hidden;
          opacity: 0;
          min-width: 5rem;
          transition: all 0.5s ease;
          margin-top: 1rem;
          display: none;
        }

        & ul li:hover > ul,
        ul li:focus-within > ul, /* this is the line we add */
        ul li ul:hover {
          visibility: visible;
          opacity: 1;
          display: block;
          position: relative;
          height: auto;
        }
      }
      & li {
        display: none;
      }

      & .icon {
        display: block;
        text-align: right;
      }
    }
  }

  .logoHolder {
    & img {
      height: 30px;
      margin-top: 10px;
    }
    & .logoText {
      padding-top: 10px;
      padding-left: 16px;
      font-size: 0.8em;
      font-weight: 100;
    }
  }
}
