@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700');
@import url('https://use.typekit.net/lki7ztn.css');

$color-black: #222;
$color-offblack: #16161d;
$color-white: #fff;
$color-offwhite: #f1f2f2;
$color-blue: #006ba4;
$color-blue-dark: #092234;
$color-blue-light: #2b8eaa;
$color-blue-bright: #00bfff;
$color-gray: #3f3f3f;
$color-gray-medium: #b0b4b8;
$color-gray-light: #e1e1e1;
$color-gray-lighter: #f7f7f7;
$color-red: #bf360c;
$color-orange: #ef6c00;
$color-yellow: #f4c20d;
$color-green: #1aaf4f;
$color-ne-orange: #fbaf5f;
$color-ne-blue: #276489;
$color-ne-blue-light: #1c95b8;
$color-ne-red-light: #eb5e46;
$color-ne-red: #b14736;

$color-pp-green: #6a6;
$color-pp-green-dark: #1E331E;

$podpop-navy: #1a2c38;
$podpop-blue: #4989c8;
$podpop-teal: #4bb6c2;
$podpop-red: #e94c24;
$podpop-green: #acd145;
$podpop-darkgrey: #939598;
$podpop-lightgrey: #e8e9e9;
$podpop-hovergrey: #f7f7f7;
$podpop-highlighthover: #f7f7f7;
$podpop-orange: #f1873b;
$podpop-white: #ffffff;
$podpop-black: #16161d;

$podpop-fontCondensed: 'niveau-grotesk', sans-serif;
$podpop-fontSans: 'niveau-grotesk', sans-serif;
$podpop-fontHead: 'aglet-slab', sans-serif;

$color-dark-divider: rgba(0, 0, 0, 0.2);

$x-small-screen: 520px;
$small-screen: 720px;
$medium-screen: 900px;

/**
   *  Animation Properties
  */
$animation-duration: 0.35s;
$animation-delay: calc($animation-duration / 5);
$animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1);
$animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1);
$animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1);
$animation-curve-default: $animation-curve-fast-out-slow-in;

/**
   *  Z-Index Properties
  */
$z-index-below: -11;
$z-index-bot: 1;
$z-index-mid: 11;
$z-index-top: 21;
$z-index-highest: 31;

/**
   *  Sortable Item Properties
  */
$sortable-item-item-height: 100px;
$sortable-item-book-cover-height: 100px;
$sortable-item-book-cover-width: 100px;
$sortable-item-collection-cover-height: 173px;
$sortable-item-collection-cover-width: 341px;
