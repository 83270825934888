h1, h2, h3, h4, h5, h6, .site-title{
  font-family:'Open Sans', 'Segoe UI', Arial, sans-serif;
  margin: 0;
}

.App {
  padding-top: 64px;
  min-height: 100vh;

  background-color: #282c34;
  color: #fff;
}

.AppLogo {
  height: 40vmin;
  pointer-events: none;

}

@media (prefers-reduced-motion: no-preference) {
  .AppLogo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.AppHeader {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.AppLink {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
