@import '../../styles/variables.scss';

.inputControl {
  position: relative;
  margin: 0;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;

  &.size--keyword {
    display: inline-block;
    margin: 0;
    width: 80%;
  }

  &.size--small {
    margin-bottom: 0;
  }

  & .customInput {
    background-color: $podpop-white;
    display: block;
    font-family: $podpop-fontSans;
    font-size: 1em;
    padding: 10px 0;
    width: 100%;
    box-sizing: border-box;

    &:disabled {
      cursor: not-allowed;
      background-color: $color-gray-light;
      color: $color-gray-light;
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $color-gray-medium;
      font-size: 0.9em;
    }

    /* &:placeholder-shown {
    border: 1px solid red; /* Red border only if the input is empty */
  }

  /* LABEL ======================================= */
  & label {
    color: $podpop-darkgrey;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: normal;
  }

  /* ICON ================================== */
  & .clear {
    color: $podpop-navy;
    font-size: 0.8em;
    position: absolute;
    right: 6px;
    top: 5px;
  }

  /* ICON ================================== */
  & .icon {
    position: absolute;
    top: 1px;
    right: 6px;
    pointer-events: none;
  }

  /* HIGHLIGHTER ================================== */
  & .highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
  }

  /* SIZES ================================= */
  &.size--small {
    & .customInput {
      font-size: 0.8em;
      padding: 4px 4px;
      margin: 0;
      border: none;
    }
  }

  &.size--keyword {
    & .customInput {
      font-size: 1em;
      padding: 4px 4px;
      margin: 0;
      border: 0.5px solid $podpop-darkgrey;

      &::placeholder {
        text-transform: capitalize;
      }
    }
  }

  /* THEMES ================================= */
  &.theme--material {
    & label {
      font-size: 0.9em;
      pointer-events: none;
      position: absolute;
      left: 5px;
      top: 10px;
      transition: 0.2s ease all;
    }

    & .customInput {
      margin-top: 18px;
      border: none;
      border-bottom: 1px solid $color-gray-light;

      /* active state */
      &:focus ~ .bar:before,
      &:focus ~ .bar:after {
        width: 50%;
      }

      &:focus ~ .highlight {
        animation: inputHighlighter 0.3s ease;
      }

      &:valid ~ label {
        top: -10px;
        left: 0;
      }

      &:invalid ~ label {
        top: -10px;
        left: 0;

        color: $podpop-red;
      }

      &:focus ~ label {
        opacity: 1;
        top: -10px;
        left: 0;

        color: $podpop-blue;
      }
    }

    /* BOTTOM BARS -------------------------------- */
    & .bar {
      position: relative;
      display: block;
      width: 100%;

      &:before {
        content: '';
        height: 2px;
        width: 0;
        bottom: 1px;
        position: absolute;
        background: $podpop-blue;
        transition: 0.2s ease all;
        left: 50%;
      }
      &:after {
        content: '';
        height: 2px;
        width: 0;
        bottom: 1px;
        position: absolute;
        background: $podpop-blue;
        transition: 0.2s ease all;
        right: 50%;
      }
    }
  }

  &.theme--standard {
    & .customInput {
      background-color: $podpop-white;
      border-radius: 1px;
      border: 0.5px solid $podpop-darkgrey;
      line-height: 1.2;
      padding: 3px 0 3px 2px;
      width: 100%;
      color: $podpop-navy;

      /* active state */
      &:focus {
        border-color: $podpop-navy;
      }
    }
  }

  // Only Library and Collections Library currently use dark theme
  &.theme--dark {
    & .customInput {
      background-color: $podpop-darkgrey;
      color: $podpop-white;

      &::placeholder {
        color: $podpop-white;
      }
    }
  }
}

/* ANIMATIONS ================ */
@keyframes inputHighlighter {
  from {
    background: $podpop-blue;
  }
  to {
    width: 0;
    background: transparent;
  }
}
