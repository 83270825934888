@import '../../styles/variables.scss';

/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 101; /* Sit on top */
  padding-top: 0; /* Location of the box */
  left: 0;
  top: 0;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal Content */
.modalContent {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close {
  position: absolute;
  color: $podpop-blue;
  top: 0;
  left: 0;
  padding: 0 10px;
  font-size: 2.5em;
  font-weight: bold;

  &:hover,
  &:focus {
    color: $podpop-orange;
    text-decoration: none;
    cursor: pointer;
  }

  & .closeIcon {
    padding: 3px;
    width: 0.65em;
  }
}

.modalHeader {
  padding: 16px 16px;
  background-color: $podpop-white;
  color: $podpop-navy;
  min-height: 36px;
}

.modalLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  margin: 8px 0;
}

.modalBody {
  padding: 10px 16px;
  margin: 5% 10%;
  max-height: 65vh;
  overflow: auto;
}

.modalFooter {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 30px;
  background-color: $podpop-white;
  color: #333;
  text-align: right;

  & button {
    margin: 0 10px;
    min-width: 250px;
  }
}

.width--normal {
  width: 50vw;
}

.width--wide {
  width: 80vw;
}

.width--small {
  width: 30vw;
}

@media only screen and (max-width: 600px) {
  .width--normal {
    width: 100vw;
  }

  .width--wide {
    width: 100vw;
  }

  .width--small {
    width: 100vw;
  }
}
