@import '../../styles/variables.scss';

.loadingContainer {
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /*centers items on the line (the x-axis by default)*/
  align-items: center; /*centers items on the cross-axis (y by default)*/
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 200;
}

.loadingBox {
  width: 60vw;
  font-size: 2em;
  border: 2px solid $podpop-navy;
  border-radius: 6px;
  padding: 60px 0;
  background-color: $color-offwhite;
  color: $podpop-blue;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inline {
  font-size: 0.8em;
}

.loadingText {
  color: $podpop-navy;
  font-size: 0.8em;
  padding-top: 30px;
}

.loadingLabel {
  color: $podpop-darkgrey;
  font-size: 0.8em;
  padding-top: 30px;
}

.ldsRipple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;

  div {
    position: absolute;
    border: 4px solid $podpop-blue;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }
}

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
