@import '../../styles/variables.scss';

.checkbox {
  width: auto;
  font-size: 14px;
  padding: 10px 10px 10px 5px;
  display: inline;
  border: none;
  border-bottom: 1px solid #757575;
  cursor: pointer;
  margin-left: 0;
  border-radius: 0;

  &:checked {
    background-color: $podpop-blue;
  }
}

.checkLabel {
  position: relative;
  font-size: inherit;
  cursor: pointer;
  pointer-events: all;
  left: 0;
  top: 0;
  color: $podpop-black;
}
