@import '../../styles/variables.scss';

.button {
  border: none;
  cursor: pointer;
  border-radius: 0;
  transition: all 300ms;
  font-family: $podpop-fontSans;

  &:focus {
    outline: none;
  }
}

.button:disabled {
  background-color: $podpop-darkgrey;
  cursor: not-allowed;
}

.size--tiny {
  min-width: 0;
  padding: 2px;
  font-size: 0.8em;
}

.size--small {
  min-width: 16px;
  padding: 2px 6px;
  font-size: 0.8em;
  margin: 5px 0;
}

.size--medium {
  border-radius: 30px;
  padding: 10px 26px;
  font-size: 1em;
  min-width: 20px;
}

.size--normal {
  border-radius: 30px;
  padding: 10px 26px;
  font-size: 1em;
  min-width: 250px;
}

.size--wide {
  width: 100%;
  border-radius: 30px;
  padding: 10px 46px;
  font-size: 1em;
}

.size--large {
  padding: 16px 18px;
  font-size: 1.2em;
}

.theme--trackButton {
  color: $color-white;
  text-align: left;
  &:hover {
    opacity: 0.8;
  }
}

.theme--success {
  background-color: $color-green;
  color: $color-white;

  &:focus,
  &:hover:enabled {
    background-color: darken($color-green, 10%);
    color: $color-white;
  }

  &.inverse {
    color: $color-green;
    fill: $color-green;
    background-color: transparent;

    &:focus,
    &:hover {
      color: darken($color-green, 10%);
      background-color: rgba($color-green, 0.05);
    }
  }
}

.theme--primary {
  background-color: $podpop-blue;
  color: $color-white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  &:hover {
    background-color: lighten($podpop-blue, 10%);
    color: $color-white;
  }

  &:active {
    background-color: $podpop-blue;
    box-shadow: none;
  }

  &:disabled {
    background-color: $podpop-darkgrey;
    color: $podpop-lightgrey;
    cursor: not-allowed;
    box-shadow: none;
    font-style: italic;
  }

  &.inverse {
    color: $podpop-blue;
    background-color: transparent;
    border: 2px solid $podpop-blue;

    &:hover {
      background-color: $color-white;
      color: lighten($podpop-blue, 10%);
      border: 2px solid lighten($podpop-blue, 10%);
    }

    &:active {
      color: $podpop-blue;
      background-color: transparent;
      box-shadow: none;
      border: 2px solid $podpop-blue;
    }

    &:disabled {
      background-color: transparent;
      color: $podpop-darkgrey;
      cursor: not-allowed;
      box-shadow: none;
      font-style: italic;
      border: 2px solid $podpop-darkgrey;
    }
  }
}

.theme--danger {
  background-color: $color-red;
  color: $color-white;

  &:focus,
  &:hover:enabled {
    background-color: darken($color-red, 10%);
    color: $color-white;
  }

  &.inverse {
    color: $color-red;
    background-color: transparent;

    &:focus,
    &:hover {
      color: darken($color-red, 10%);
      background-color: rgba($color-red, 0.05);
    }
  }
}

.theme--warning {
  background-color: $color-orange;
  color: $color-white;

  &:focus,
  &:hover:enabled {
    background-color: darken($color-orange, 10%);
    color: $color-white;
  }

  &.inverse {
    color: $color-red;
    background-color: transparent;

    &:focus,
    &:hover {
      color: darken($color-orange, 10%);
      background-color: rgba($color-orange, 0.05);
    }
  }
}

.theme--light {
  background-color: $color-white;
  color: $color-black;

  &:focus,
  &:hover {
    background-color: darken($color-white, 10%);
    color: $color-black;
  }

  &.inverse {
    color: $color-white;
    background-color: transparent;

    &:focus,
    &:hover {
      color: $color-black;
      background-color: rgba($color-white, 0.05);
    }
  }
}

.theme--dark {
  background-color: $color-black;
  color: $color-white;

  &:focus,
  &:hover {
    background-color: darken($color-black, 10%);
    color: $color-white;
  }

  &.inverse {
    color: $color-black;
    background-color: transparent;

    &:focus,
    &:hover {
      color: $color-white;
      background-color: rgba($color-black, 0.05);
    }
  }
}

.link {
  background-color: transparent;
  color: $color-blue-light;

  &:focus,
  &:hover:enabled {
    background-color: $color-gray-lighter;
    color: darken($color-blue-light, 10%);
  }
}

.wide {
  width: 100%;
}

.children {
  white-space: nowrap;
}
